"use client";

import { useDispatch } from "react-redux";

import { AnimationFadeIn } from "@Components/Animation";
import { Button } from "@Components/Button";
import { Container } from "@Components/Container";
import { Image } from "@Components/Image";
import { Text } from "@Components/Typography";
import { useTranslation } from "@Core/i18n/client";
import useWindowSize from "@Hooks/windowSize";
import { openContactUsModal } from "@Modals/ContactUsModal";
import * as utils from "@Utils";

import imageCirclesTablet from "./images/circles-t.svg";
import imageCircles from "./images/circles.svg";
import { PhotosGrid } from "./PhotosGrid";

type SectionProps = {
    className?: string;
};

export const SectionFeedback: React.FC<SectionProps> = ({ className }) => {
    const dispatch = useDispatch();
    const { t: tHome } = useTranslation("home");

    const windowSize = useWindowSize();

    const handleContactUsClick = () => {
        dispatch(openContactUsModal());
    };

    return (
        <section
            className={utils.twcx(
                "relative z-0 pt-[50px] md:pt-[147px]",
                className
            )}
        >
            <Container className="flex flex-col items-center gap-y-3 text-center md:gap-y-4">
                <AnimationFadeIn>
                    <Text
                        className="max-w-[480px] bg-gradient-03 bg-clip-text text-transparent md:max-w-[640px]"
                        tag="h2"
                        variant="h3"
                        weight={700}
                    >
                        {tHome("sections.feedback.title")}
                    </Text>
                </AnimationFadeIn>

                <AnimationFadeIn>
                    <Text className="max-w-[400px] md:max-w-[530px]">
                        {tHome("sections.feedback.text")}
                    </Text>
                </AnimationFadeIn>

                <AnimationFadeIn className="mt-3 md:mt-4" parent>
                    <Button
                        className="min-w-[180px]"
                        onClick={handleContactUsClick}
                    >
                        {tHome("sections.feedback.buttonContactUs")}
                    </Button>
                </AnimationFadeIn>
            </Container>

            <PhotosGrid className="mt-[38px] h-[310px] md:mt-[94px] md:h-[443px]" />

            {windowSize.isMd && (
                <Image
                    className="pointer-events-none absolute left-1/2 top-[-62px] -z-10 w-[1641px] max-w-none -translate-x-1/2 select-none max-md:hidden"
                    src={imageCircles}
                    alt="Circles"
                    size="1641px"
                />
            )}

            {!windowSize.isMd && (
                <Image
                    className="pointer-events-none absolute left-1/2 top-[9px] -z-10 w-[641px] max-w-none -translate-x-1/2 select-none md:hidden"
                    src={imageCirclesTablet}
                    alt="Circles"
                    size="641px"
                />
            )}
        </section>
    );
};
