import * as _ from "lodash-es";
import NextImage, { ImageProps as NextImageProps } from "next/image";
import { forwardRef, Ref, useMemo, useState } from "react";

import { SvgIcon } from "@Components/SvgIcon";
import * as utils from "@Utils";

export type ImageProps = Omit<NextImageProps, "src"> & {
    classNameWrapper?: string;
    classNameLoader?: string;
    src?: NextImageProps["src"] | null;
    size?: string;
    sizeXs?: string;
    sizeSm?: string;
    sizeMd?: string;
    sizeLg?: string;
    sizeXl?: string;
    size2Xl?: string;
    size3Xl?: string;
    withLoader?: boolean;
};

const ImageWithLoader = forwardRef<HTMLDivElement, ImageProps>(
    ({ classNameWrapper, classNameLoader, src, ...props }, ref) => {
        const [isLoading, setIsLoading] = useState<boolean>(true);

        const memoImage = useMemo(
            () =>
                src ? (
                    <NextImage
                        src={src}
                        {...props}
                        onLoad={e => {
                            if (props.onLoad) {
                                props.onLoad(e);
                            }

                            setIsLoading(false);
                        }}
                        onError={e => {
                            if (props.onError) {
                                props.onError(e);
                            }

                            setIsLoading(false);
                        }}
                    />
                ) : null,
            [src]
        );

        if (!src) {
            return null;
        }

        return (
            <div
                ref={ref}
                className={utils.twcx(
                    "relative z-0 h-full w-full",
                    classNameWrapper
                )}
            >
                {isLoading && (
                    <div
                        className={utils.twcx(
                            "loader absolute inset-0 z-10",
                            classNameLoader
                        )}
                    ></div>
                )}

                {memoImage}
            </div>
        );
    }
);

ImageWithLoader.displayName = "ImageWithLoader";

export const Image = forwardRef<HTMLDivElement, ImageProps>(
    (
        {
            classNameWrapper,
            classNameLoader,
            src,
            size,
            sizeXs,
            sizeSm,
            sizeMd,
            sizeLg,
            sizeXl,
            size2Xl,
            size3Xl,
            withLoader,
            ...props
        },
        ref
    ) => {
        const getSizes = () => {
            const sizes = [];

            if (sizeXs) {
                sizes.push(
                    `(min-width: ${utils.getThemeScreens("xs")}) ${sizeXs}`
                );
            }

            if (sizeSm) {
                sizes.push(
                    `(min-width: ${utils.getThemeScreens("sm")}) ${sizeSm}`
                );
            }

            if (sizeMd) {
                sizes.push(
                    `(min-width: ${utils.getThemeScreens("md")}) ${sizeMd}`
                );
            }

            if (sizeLg) {
                sizes.push(
                    `(min-width: ${utils.getThemeScreens("lg")}) ${sizeLg}`
                );
            }

            if (sizeXl) {
                sizes.push(
                    `(min-width: ${utils.getThemeScreens("xl")}) ${sizeXl}`
                );
            }

            if (size2Xl) {
                sizes.push(
                    `(min-width: ${utils.getThemeScreens("2xl")}) ${size2Xl}`
                );
            }

            if (size3Xl) {
                sizes.push(
                    `(min-width: ${utils.getThemeScreens("3xl")}) ${size3Xl}`
                );
            }

            if (size) {
                sizes.push(size);
            }

            if (_.isEmpty(sizes)) {
                return;
            }

            return sizes.join(", ");
        };

        if (!src) {
            return (
                <div
                    ref={ref}
                    className={utils.twcx(
                        "bg-gray-08 text-gray-05 flex h-full w-full items-center justify-center overflow-hidden",
                        props.className
                    )}
                >
                    <SvgIcon className="h-3/4 w-3/4" icon="image" />
                </div>
            );
        }

        const imageProps = {
            src,
            sizes: getSizes(),
            quality: 90,
            ...props,
        };

        if (withLoader) {
            return (
                <ImageWithLoader
                    ref={ref}
                    classNameWrapper={classNameWrapper}
                    classNameLoader={classNameLoader}
                    {...imageProps}
                />
            );
        }

        return <NextImage ref={ref as Ref<HTMLImageElement>} {...imageProps} />;
    }
);

Image.displayName = "Image";
