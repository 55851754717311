import {
    Attributes,
    Children,
    cloneElement,
    forwardRef,
    isValidElement,
    PropsWithChildren,
    useEffect,
    useState,
} from "react";
import { useInView } from "react-intersection-observer";

import * as utils from "@Utils";

type AnimationProps = PropsWithChildren & {
    className?: string;
    type?: "fadeIn" | "fadeInTop" | "fadeInBottom";
    offset?: number;
    delay?: number;
    parent?: boolean;
    done?: boolean;
};

export const AnimationFadeIn = forwardRef<HTMLDivElement, AnimationProps>(
    (
        {
            className,
            type = "fadeInBottom",
            offset = -50,
            delay,
            parent = false,
            done = false,
            children,
        },
        ref
    ) => {
        const [isDone, setIsDone] = useState(done);

        const {
            ref: refInView,
            inView,
            entry,
        } = useInView({
            rootMargin: `${offset}px 0px`,
            delay,
            triggerOnce: true,
        });

        const isAnimated = inView || isDone;

        const classNameProp = utils.twcx(
            "transition-[opacity,transform] duration-700",
            {
                "opacity-0": !isAnimated,
                "translate-y-[-50px]": type === "fadeInTop" && !isAnimated,
                "translate-y-[50px]": type === "fadeInBottom" && !isAnimated,
            },
            className
        );

        const styleProp = {
            transitionDelay: delay ? `${delay}ms` : undefined,
        };

        const setRefs = (node: HTMLDivElement) => {
            refInView(node);

            if (typeof ref === "function") {
                ref(node);
            } else if (ref) {
                ref.current = node;
            }
        };

        useEffect(() => {
            if (entry?.target) {
                if (entry.target.getBoundingClientRect().left > innerWidth) {
                    setIsDone(true);
                }
            }
        }, [entry]);

        return (
            <>
                {Children.map(children, child => {
                    if (isValidElement(child) && !parent) {
                        return cloneElement(child, {
                            ref: setRefs,
                            className: utils.twcx(
                                child?.props?.className,
                                classNameProp
                            ),
                            style: styleProp,
                        } as Attributes);
                    }

                    return (
                        <div
                            ref={setRefs}
                            className={classNameProp}
                            style={styleProp}
                        >
                            {child}
                        </div>
                    );
                })}
            </>
        );
    }
);

AnimationFadeIn.displayName = "AnimationFadeIn";
