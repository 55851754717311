"use client";

import { useEffect, useRef } from "react";

import { PAGES } from "@Settings/pages";
import { AnimationFadeIn } from "@Components/Animation";
import { Button } from "@Components/Button";
import { Container } from "@Components/Container";
import { Image } from "@Components/Image";
import { Text } from "@Components/Typography";
import { useTranslation } from "@Core/i18n/client";
import useWindowSize from "@Hooks/windowSize";
import * as utils from "@Utils";

import imageLines from "./images/lines.svg";
import imagePhones1Tablet from "./images/phones-1-t.webp";
import imagePhones1 from "./images/phones-1.webp";
import imagePhones2Tablet from "./images/phones-2-t.webp";
import imagePhones2 from "./images/phones-2.webp";
import imagePhones3Tablet from "./images/phones-3-t.webp";
import imagePhones3 from "./images/phones-3.webp";

type SectionProps = {
    className?: string;
};

export const SectionProductOptions: React.FC<SectionProps> = ({
    className,
}) => {
    const { t: tHome } = useTranslation("home");

    const windowSize = useWindowSize();

    const refSection = useRef<HTMLDivElement>(null);
    const refContainerContentWrapper = useRef<HTMLDivElement>(null);
    const refContainerContent = useRef<HTMLDivElement>(null);
    const refContainerCards = useRef<HTMLDivElement>(null);
    const refCardWrappers = useRef<HTMLDivElement[]>([]);
    const refCards = useRef<HTMLDivElement[]>([]);
    const refLinesContainer = useRef<HTMLDivElement>(null);
    const refLinesSticky = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (
            !refSection.current ||
            !refContainerContentWrapper.current ||
            !refContainerContent.current ||
            !refContainerCards.current ||
            !refCardWrappers.current.length ||
            !refCards.current.length
        ) {
            return;
        }

        [
            refContainerContentWrapper.current,
            refContainerContent.current,
            refContainerCards.current,
            ...refCardWrappers.current,
            ...refCards.current,
            refLinesSticky.current,
        ].forEach(el => {
            if (el) {
                el.removeAttribute("style");
            }
        });

        const cardMaxHeight = refCards.current.reduce((max, card) => {
            return Math.max(max, card.offsetHeight);
        }, 0);

        refCards.current.forEach(card => {
            card.style.minHeight = `${cardMaxHeight}px`;
        });

        const headerHeight = utils.getHeaderHeight();
        const distanceBetweenCards = windowSize.isMd ? 120 : 72;
        const sectionBCR = refSection.current.getBoundingClientRect();

        const containerContentBCR =
            refContainerContent.current.getBoundingClientRect();

        const containerCardsBCR =
            refContainerCards.current.getBoundingClientRect();

        const cardWrapperFirstBCR =
            refCardWrappers.current[0].getBoundingClientRect();

        const totalFoldedHeight =
            cardWrapperFirstBCR.bottom -
            sectionBCR.top +
            refCardWrappers.current.reduce(
                (sum, cardWrapper, cardWrapperIndex) => {
                    if (!cardWrapperIndex) {
                        return sum;
                    }

                    return (
                        sum +
                        Math.max(
                            0,
                            cardWrapper.offsetHeight -
                                cardWrapperFirstBCR.height +
                                distanceBetweenCards
                        )
                    );
                },
                0
            );

        const isPinToBottom = totalFoldedHeight > innerHeight - headerHeight;
        const pinToBottomOffset = isPinToBottom
            ? innerHeight - headerHeight - totalFoldedHeight
            : 0;
        const topPositionOffset = headerHeight + pinToBottomOffset;
        const cardsStartTopPosition = cardWrapperFirstBCR.top - sectionBCR.top;

        let containerContentWrapperMinHeight = 0;
        let containerContentTop = 0;
        let containerCardsMarginTop = 0;
        let linesStickyTop = 0;
        let linesStickyMinHeight = 0;

        refCardWrappers.current.forEach((cardWrapper, cardWrapperIndex) => {
            const cardWrapperNext =
                refCardWrappers.current[cardWrapperIndex + 1];

            if (!cardWrapperNext) {
                return;
            }

            const cardWrapperLast =
                refCardWrappers.current[refCardWrappers.current.length - 1];

            const card = refCards.current[cardWrapperIndex];
            const cardWrapperBCR = cardWrapper.getBoundingClientRect();
            const cardWrapperLastBCR = cardWrapperLast.getBoundingClientRect();

            const distanceBetweenCardsMul =
                distanceBetweenCards *
                (refCardWrappers.current.length - 1 - cardWrapperIndex);

            const cardWrapperMinHeight =
                cardWrapperLastBCR.bottom -
                cardWrapperBCR.top -
                distanceBetweenCardsMul;

            const cardWrapperNextMarginTop =
                cardWrapperLastBCR.bottom -
                cardWrapperBCR.bottom -
                distanceBetweenCardsMul;

            const cardTop =
                cardsStartTopPosition +
                cardWrapperIndex * distanceBetweenCards +
                topPositionOffset;

            if (!cardWrapperIndex) {
                containerContentWrapperMinHeight =
                    cardWrapperMinHeight -
                    (cardWrapperBCR.height - containerContentBCR.height);

                containerCardsMarginTop =
                    containerContentWrapperMinHeight -
                    (containerCardsBCR.top - containerContentBCR.top);

                containerContentTop =
                    containerContentBCR.top -
                    sectionBCR.top +
                    topPositionOffset;

                if (refLinesContainer.current && refLinesSticky.current) {
                    linesStickyTop = topPositionOffset;
                    linesStickyMinHeight =
                        refLinesContainer.current.offsetHeight -
                        cardWrapperMinHeight +
                        cardWrapperBCR.height;
                }
            }

            card.style.top = `${cardTop}px`;
            cardWrapper.style.minHeight = `${cardWrapperMinHeight}px`;
            cardWrapperNext.style.marginTop = `-${cardWrapperNextMarginTop}px`;
        });

        if (refCards.current.length > 1) {
            refContainerContentWrapper.current.style.minHeight = `${containerContentWrapperMinHeight}px`;
            refContainerContent.current.style.top = `${containerContentTop}px`;
            refContainerCards.current.style.marginTop = `-${containerCardsMarginTop}px`;

            if (refLinesContainer.current && refLinesSticky.current) {
                refLinesSticky.current.style.top = `${linesStickyTop}px`;
                refLinesSticky.current.style.minHeight = `${linesStickyMinHeight}px`;
            }
        }
    }, [windowSize.width]);

    return (
        <section
            ref={refSection}
            className={utils.twcx(
                "relative z-0 py-[50px] md:py-[80px]",
                className
            )}
            id={utils.getIdFromLink(PAGES.home.sections.productOptions.link)}
        >
            <div ref={refContainerContentWrapper}>
                <Container ref={refContainerContent} className="sticky" grid>
                    <AnimationFadeIn>
                        <Text
                            className="col-span-full -mb-3 bg-gradient-01 bg-clip-text pb-3 text-transparent sm:col-span-5 md:col-span-7"
                            tag="h2"
                            variant="h2"
                            weight={600}
                        >
                            {tHome("sections.productOptions.title")}
                        </Text>
                    </AnimationFadeIn>

                    <AnimationFadeIn>
                        <Text
                            className="col-span-full row-start-2 mt-3 max-md:text-steel-light sm:col-span-4 md:col-span-4 md:mt-4"
                            tag="p"
                            color="white"
                        >
                            {tHome("sections.productOptions.text")}
                        </Text>
                    </AnimationFadeIn>
                </Container>
            </div>

            <Container
                ref={refContainerCards}
                className="mt-6 flex flex-col gap-4 md:mt-8"
            >
                {["models", "influencers", "dealer"].map(cardId => (
                    <div
                        key={cardId}
                        ref={el => {
                            if (el && !refCardWrappers.current.includes(el)) {
                                refCardWrappers.current.push(el);
                            }
                        }}
                    >
                        <div
                            ref={el => {
                                if (el && !refCards.current.includes(el)) {
                                    refCards.current.push(el);
                                }
                            }}
                            className="sticky z-0 flex overflow-hidden rounded-[30px] bg-white px-4 py-6 shadow-[0px_-4px_30px_rgba(35,36,66,0.3)] max-sm:flex-col sm:gap-x-3 md:gap-x-6 md:rounded-[40px] md:px-10 md:py-8 md:shadow-[0px_-4px_40px_rgba(35,36,66,0.4)]"
                        >
                            <div className="sm:max-w-[60%] md:max-w-[50%] md:pt-7">
                                <Text
                                    className="max-md:text-h3-m"
                                    tag="h3"
                                    variant="h4"
                                    weight={700}
                                    color="steel01"
                                >
                                    {tHome(
                                        `sections.productOptions.cards.${cardId}.title`
                                    )}
                                </Text>

                                <Text
                                    className="mt-2 md:mt-3"
                                    variant="p2"
                                    color="steel02"
                                    wysiwyg
                                    html={tHome(
                                        `sections.productOptions.cards.${cardId}.text`
                                    )}
                                />

                                <Button
                                    className="mt-5 max-md:min-w-[158px] md:mt-8"
                                    variant="outline"
                                    size="sm"
                                >
                                    {tHome(
                                        `sections.productOptions.buttonChooseAnOption`
                                    )}
                                </Button>
                            </div>

                            {windowSize.isMd && (
                                <Image
                                    className={utils.twcx(
                                        "pointer-events-none mb-[-228px] mr-[-86px] mt-[-32px] w-[686px] max-w-none shrink-0 select-none self-end max-md:hidden",
                                        {
                                            "mb-[-224px]":
                                                cardId === "influencers",
                                            "mb-[-225px]": cardId === "dealer",
                                        }
                                    )}
                                    src={
                                        {
                                            models: imagePhones1,
                                            influencers: imagePhones2,
                                            dealer: imagePhones3,
                                        }[cardId]
                                    }
                                    alt={cardId}
                                    size="686px"
                                />
                            )}

                            {!windowSize.isMd && (
                                <Image
                                    className={utils.twcx(
                                        "pointer-events-none mb-[-161px] mt-[16px] w-[421px] max-w-none shrink-0 select-none self-center max-sm:ml-[43px] sm:mr-[-60px] sm:self-end md:hidden",
                                        {
                                            "mt-[-3px] max-sm:ml-[44px]":
                                                cardId === "influencers",
                                            "mt-[4px] max-sm:ml-[46px]":
                                                cardId === "dealer",
                                        }
                                    )}
                                    src={
                                        {
                                            models: imagePhones1Tablet,
                                            influencers: imagePhones2Tablet,
                                            dealer: imagePhones3Tablet,
                                        }[cardId]
                                    }
                                    alt={cardId}
                                    size="421px"
                                />
                            )}
                        </div>
                    </div>
                ))}
            </Container>

            <div
                ref={refLinesContainer}
                className="pointer-events-none absolute inset-0 -z-10"
            >
                <div ref={refLinesSticky} className="sticky">
                    <div className="absolute inset-0 mb-[-1500px] mt-[-300px] overflow-hidden">
                        <Image
                            className="pointer-events-none absolute left-1/2 top-[-46px] -z-10 ml-[-1227px] w-[2355px] max-w-none rotate-[54.51deg] select-none sm:top-[150px] md:top-[373px] md:ml-[-989px] md:rotate-[30.39deg] xl:ml-[-69%] xl:w-[164%]"
                            src={imageLines}
                            alt="Lines"
                            size="2355px"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};
