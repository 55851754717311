"use client";

import { PAGES } from "@Settings/pages";
import { AnimationFadeIn } from "@Components/Animation";
import { Container } from "@Components/Container";
import { Image } from "@Components/Image";
import { Text } from "@Components/Typography";
import { useTranslation } from "@Core/i18n/client";
import useWindowSize from "@Hooks/windowSize";
import * as utils from "@Utils";

import imageSchemeTablet from "./images/scheme-t.webp";
import imageScheme from "./images/scheme.webp";

type SectionProps = {
    className?: string;
};

export const SectionPlatforms: React.FC<SectionProps> = ({ className }) => {
    const { t: tHome } = useTranslation("home");

    const windowSize = useWindowSize();

    return (
        <section
            className={utils.twcx(
                "relative z-0 pb-[30px] pt-[50px] md:pb-[60px] md:pt-[67px] md:before:pointer-events-none md:before:absolute md:before:left-1/2 md:before:top-[24px] md:before:-z-10 md:before:block md:before:h-[796px] md:before:w-[1091px] md:before:-translate-x-1/2 md:before:rounded-[100%] md:before:bg-gradient-radial-half md:before:from-[#8B94D5]/80 md:before:to-[#8B94D5]/0 md:before:blur-[50px]",
                className
            )}
            id={utils.getIdFromLink(PAGES.home.sections.platforms.link)}
        >
            <Container grid>
                <AnimationFadeIn>
                    <Text
                        className="col-span-full sm:col-span-4 md:col-span-6"
                        tag="h2"
                        variant="h2"
                        weight={600}
                        color="white"
                    >
                        {tHome("sections.platforms.title")}
                    </Text>
                </AnimationFadeIn>

                <AnimationFadeIn delay={windowSize.isSm ? 150 : undefined}>
                    <Text
                        className="col-span-full max-sm:mt-3 sm:col-span-4 md:col-span-6 md:pt-1.5"
                        tag="p"
                    >
                        {tHome("sections.platforms.text")}
                    </Text>
                </AnimationFadeIn>

                {windowSize.isXs && (
                    <AnimationFadeIn>
                        <Image
                            className="pointer-events-none col-span-full mt-6 w-full max-w-[960px] select-none justify-self-center max-xs:hidden md:mt-[50px]"
                            src={imageScheme}
                            alt="Scheme"
                            size="100vw"
                            sizeMd="960px"
                        />
                    </AnimationFadeIn>
                )}

                {!windowSize.isXs && (
                    <AnimationFadeIn>
                        <Image
                            className="pointer-events-none col-span-full mt-6 w-screen max-w-none select-none justify-self-center xs:hidden"
                            src={imageSchemeTablet}
                            alt="Scheme"
                            size="100vw"
                        />
                    </AnimationFadeIn>
                )}
            </Container>
        </section>
    );
};
