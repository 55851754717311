"use client";

import { AnimationFadeIn } from "@Components/Animation";
import { Container } from "@Components/Container";
import { Image } from "@Components/Image";
import { Text } from "@Components/Typography";
import { useTranslation } from "@Core/i18n/client";
import useWindowSize from "@Hooks/windowSize";
import * as utils from "@Utils";

import imagePhotosTablet from "./images/photos-t.webp";
import imagePhotos from "./images/photos.webp";

type SectionProps = {
    className?: string;
};

export const SectionCreativity: React.FC<SectionProps> = ({ className }) => {
    const { t: tHome } = useTranslation("home");

    const windowSize = useWindowSize();

    return (
        <section
            className={utils.twcx(
                "relative z-0 py-[50px] md:py-[80px]",
                className
            )}
        >
            <Container className="gap-y-6 sm:items-center" grid>
                <div className="relative z-10 col-span-full flex flex-col gap-y-3 sm:order-1 sm:col-span-4 md:col-span-6 md:gap-y-4 md:pb-4 md:pl-6">
                    <AnimationFadeIn delay={windowSize.isSm ? 150 : undefined}>
                        <Text
                            className="-mb-3 bg-gradient-03 bg-clip-text pb-3 text-transparent md:bg-gradient-01"
                            tag="h2"
                            variant="h2"
                            weight={600}
                        >
                            {tHome("sections.creativity.title")}
                        </Text>
                    </AnimationFadeIn>

                    <AnimationFadeIn>
                        <Text
                            className="sm:pr-10"
                            wysiwyg
                            html={tHome("sections.creativity.text")}
                        />
                    </AnimationFadeIn>
                </div>

                {windowSize.isMd && (
                    <AnimationFadeIn
                        className="col-span-6 max-md:hidden"
                        parent
                    >
                        <Image
                            className="pointer-events-none mx-[-250px] mb-[-254px] mt-[-246px] w-[1016px] max-w-none select-none"
                            src={imagePhotos}
                            alt="Photos"
                            size="1016px"
                        />
                    </AnimationFadeIn>
                )}

                {!windowSize.isMd && (
                    <AnimationFadeIn
                        className="col-span-full justify-self-center sm:col-span-4 md:hidden"
                        parent
                    >
                        <Image
                            className="pointer-events-none mb-[calc(-254/360*100vw)] mt-[calc(-246/360*100vw)] w-[calc(836/360*100vw)] max-w-none select-none sm:mx-[-250px] sm:mb-[-254px] sm:mt-[-246px] sm:w-[836px]"
                            src={imagePhotosTablet}
                            alt="Photos"
                            size="240vw"
                            sizeSm="836px"
                        />
                    </AnimationFadeIn>
                )}
            </Container>
        </section>
    );
};
