import { useMemo } from "react";

import { AnimationFadeIn } from "@Components/Animation";
import { Image } from "@Components/Image";
import useWindowSize from "@Hooks/windowSize";
import * as utils from "@Utils";

const getPhotosCountInColFromIndex = (colIndex: number, isTablet?: boolean) => {
    if (
        colIndex === 0 ||
        colIndex === 11 ||
        (!isTablet && (colIndex === 1 || colIndex === 10))
    ) {
        return 4;
    } else if (
        colIndex === 2 ||
        colIndex === 9 ||
        colIndex === 3 ||
        colIndex === 8 ||
        (isTablet && (colIndex === 4 || colIndex === 7)) ||
        (isTablet && (colIndex === 1 || colIndex === 10))
    ) {
        return 3;
    }

    return 2;
};

type PhotosGridProps = {
    className?: string;
};

export const PhotosGrid: React.FC<PhotosGridProps> = ({ className }) => {
    const windowSize = useWindowSize();

    const photosGrid = useMemo(() => {
        let photoId = 0;

        return Array(12)
            .fill(null)
            .map((_, colIndex) => {
                return Array(
                    getPhotosCountInColFromIndex(colIndex, !windowSize.isMd)
                )
                    .fill(null)
                    .map(() => {
                        photoId++;

                        return photoId;
                    });
            });
    }, [windowSize.isMd]);

    return (
        <div
            className={utils.twcx(
                "pointer-events-none flex justify-center gap-2 md:gap-[14px]",
                className
            )}
        >
            {photosGrid.map((col, colIndex) => {
                return (
                    <div
                        key={colIndex}
                        className={utils.twcx(
                            "flex shrink-0 flex-col gap-2 md:gap-[14px]",
                            {
                                "mt-[-53px] md:mt-[-74px]": [4, 7].includes(
                                    colIndex
                                ),
                                "mt-[-78px] md:mt-[-230px]": [3, 8].includes(
                                    colIndex
                                ),
                                "mt-[-110px] md:mt-[-328px]": [2, 9].includes(
                                    colIndex
                                ),
                                "mt-[-150px] md:mt-[-508px]": [1, 10].includes(
                                    colIndex
                                ),
                                "mt-[-190px] md:mt-[-606px]": [0, 11].includes(
                                    colIndex
                                ),
                            }
                        )}
                    >
                        {col.map(photoId => {
                            return (
                                <AnimationFadeIn type="fadeIn" key={photoId}>
                                    <Image
                                        className="pointer-events-none h-[156px] w-[104px] shrink-0 select-none rounded-xl object-cover md:h-[264px] md:w-[176px] md:rounded-[20px] min-[2200px]:h-[12vw] min-[2200px]:w-[8vw]"
                                        src={require(
                                            `./images/photos/${photoId}.webp`
                                        )}
                                        alt={`Photo ${photoId}`}
                                        size="104px"
                                        sizeMd="176px"
                                        size2Xl="8vw"
                                    />
                                </AnimationFadeIn>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};
