"use client";

import { useDispatch } from "react-redux";

import { AnimationFadeIn } from "@Components/Animation";
import { Button } from "@Components/Button";
import { Container } from "@Components/Container";
import { Image } from "@Components/Image";
import { Text } from "@Components/Typography";
import { useTranslation } from "@Core/i18n/client";
import useWindowSize from "@Hooks/windowSize";
import { openContactUsModal } from "@Modals/ContactUsModal";
import * as utils from "@Utils";

import imageBgTablet from "./images/bg-t.webp";
import imageBg from "./images/bg.webp";
import imageHand from "./images/hand.webp";

type SectionProps = {
    className?: string;
};

export const SectionCta: React.FC<SectionProps> = ({ className }) => {
    const dispatch = useDispatch();
    const { t: tHome } = useTranslation("home");

    const windowSize = useWindowSize();

    const handleContactUsClick = () => {
        dispatch(openContactUsModal());
    };

    return (
        <section
            className={utils.twcx(
                "relative z-[1] px-3 pb-[50px] pt-[30px] md:pb-[80px] md:pt-[60px]",
                className
            )}
        >
            <Container className="relative z-0 flex justify-between overflow-hidden rounded-[30px] px-4 py-10 text-white before:pointer-events-none before:absolute before:inset-0 before:-z-10 before:block before:bg-[#000221] before:bg-opacity-[0.12] after:pointer-events-none after:absolute after:left-0 after:right-0 after:top-0 after:-z-20 after:block after:h-[540px] after:bg-gradient-to-b after:from-[#60659C] after:to-[#272949]/0 max-sm:flex-col sm:after:right-auto sm:after:-z-10 sm:after:h-full sm:after:w-[564px] sm:after:bg-gradient-to-r md:min-h-[540px] md:rounded-[40px] md:px-[60px] md:py-[87px] lg:px-[60px]">
                <div className="relative z-10 flex flex-col items-start gap-y-3 sm:max-w-[440px]">
                    <AnimationFadeIn>
                        <Text tag="h2" variant="h2" weight={600}>
                            {tHome("sections.cta.title")}
                        </Text>
                    </AnimationFadeIn>

                    <AnimationFadeIn>
                        <Text className="max-w-[80%] sm:max-w-none" tag="p">
                            {tHome("sections.cta.text")}
                        </Text>
                    </AnimationFadeIn>

                    <AnimationFadeIn className="mt-3 md:mt-5" parent>
                        <Button onClick={handleContactUsClick}>
                            {tHome("sections.cta.buttonContactUs")}
                        </Button>
                    </AnimationFadeIn>
                </div>

                <AnimationFadeIn delay={150}>
                    <Image
                        className="pointer-events-none mb-[-208px] ml-[166px] mt-[-185px] w-[934px] max-w-none shrink-0 select-none self-center sm:ml-[-200px] sm:mr-[-300px] sm:mt-[-210px] sm:w-[800px] md:mb-[-294px] md:ml-[-100px] md:mr-[-360px] md:mt-[-309px] md:w-[1006px]"
                        src={imageHand}
                        alt="Hand"
                        size="934px"
                        sizeSm="800px"
                        sizeMd="1006px"
                    />
                </AnimationFadeIn>

                {windowSize.isSm && (
                    <Image
                        className="pointer-events-none absolute inset-0 -z-30 h-full w-full select-none object-cover max-sm:hidden"
                        src={imageBg}
                        alt="Background"
                        size="1180px"
                    />
                )}

                {!windowSize.isSm && (
                    <Image
                        className="pointer-events-none absolute inset-0 -z-30 h-full w-full select-none object-cover sm:hidden"
                        src={imageBgTablet}
                        alt="Background"
                        size="100vw"
                    />
                )}
            </Container>
        </section>
    );
};
