"use client";

import { AnimationFadeIn } from "@Components/Animation";
import { Container } from "@Components/Container";
import { Image } from "@Components/Image";
import { Text } from "@Components/Typography";
import { useTranslation } from "@Core/i18n/client";
import useWindowSize from "@Hooks/windowSize";
import * as utils from "@Utils";

import imageDevicesTablet from "./images/devices-t.webp";
import imageDevices from "./images/devices.webp";

type SectionProps = {
    className?: string;
};

export const SectionSpace: React.FC<SectionProps> = ({ className }) => {
    const { t: tHome } = useTranslation("home");

    const windowSize = useWindowSize();

    return (
        <section
            className={utils.twcx(
                "relative z-0 py-[50px] md:py-[80px]",
                className
            )}
        >
            <Container className="gap-y-6 sm:items-center" grid>
                <div className="relative z-10 col-span-full flex flex-col gap-y-3 sm:col-span-4 md:col-span-5 md:gap-y-4 md:pb-8">
                    <AnimationFadeIn>
                        <Text
                            className="-mb-3 bg-gradient-03 bg-clip-text pb-3 text-transparent"
                            tag="h2"
                            variant="h2"
                            weight={600}
                        >
                            {tHome("sections.space.title")}
                        </Text>
                    </AnimationFadeIn>

                    <AnimationFadeIn>
                        <Text wysiwyg html={tHome("sections.space.text")} />
                    </AnimationFadeIn>
                </div>

                {windowSize.isMd && (
                    <AnimationFadeIn
                        className="col-span-7 justify-self-end max-md:hidden"
                        delay={150}
                        parent
                    >
                        <Image
                            className="pointer-events-none m-[-200px] w-[1030px] max-w-none select-none"
                            src={imageDevices}
                            alt="Photos"
                            size="1030px"
                        />
                    </AnimationFadeIn>
                )}

                {!windowSize.isMd && (
                    <AnimationFadeIn
                        className="col-span-full justify-self-center sm:col-span-4 md:hidden"
                        delay={windowSize.isSm ? 150 : undefined}
                        parent
                    >
                        <Image
                            className="pointer-events-none my-[calc(-108/360*100vw)] w-[calc(548/360*100vw)] max-w-none select-none sm:m-[-108px] sm:w-[548px]"
                            src={imageDevicesTablet}
                            alt="Photos"
                            size="155vw"
                            sizeSm="548px"
                        />
                    </AnimationFadeIn>
                )}
            </Container>
        </section>
    );
};
