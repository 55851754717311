"use client";

import { PAGES } from "@Settings/pages";
import { AnimationFadeIn } from "@Components/Animation";
import { Container } from "@Components/Container";
import { Image } from "@Components/Image";
import { Text } from "@Components/Typography";
import { useTranslation } from "@Core/i18n/client";
import useWindowSize from "@Hooks/windowSize";
import * as utils from "@Utils";

import imageChart1 from "./images/chart-1.svg";
import imageChart2Tablet from "./images/chart-2-t.svg";
import imageChart2 from "./images/chart-2.svg";
import imageLines from "./images/lines.svg";
import imageTriangle from "./images/triangle.svg";

type SectionProps = {
    className?: string;
};

export const SectionStatistics: React.FC<SectionProps> = ({ className }) => {
    const { t: tHome } = useTranslation("home");

    const windowSize = useWindowSize();

    return (
        <section
            className={utils.twcx(
                "relative z-0 py-[50px] md:py-[80px]",
                className
            )}
            // id={utils.getIdFromLink(PAGES.home.sections.statistics.link)}
        >
            <Container className="gap-y-3 sm:gap-y-4 md:gap-y-8" grid>
                <AnimationFadeIn>
                    <Text
                        className="col-span-full -mb-3 self-start bg-gradient-03 bg-clip-text pb-3 text-transparent sm:col-span-4 md:col-span-6"
                        tag="h2"
                        variant="h2"
                        weight={600}
                    >
                        {tHome("sections.statistics.title")}
                    </Text>
                </AnimationFadeIn>

                <AnimationFadeIn delay={windowSize.isSm ? 150 : undefined}>
                    <Text
                        className="col-span-full sm:col-span-4 md:col-span-6"
                        tag="p"
                    >
                        {tHome("sections.statistics.text")}
                    </Text>
                </AnimationFadeIn>

                {["units", "sales"].map((cardId, cardIndex) => (
                    <AnimationFadeIn
                        key={cardId}
                        delay={
                            windowSize.isSm ? (cardIndex % 2) * 100 : undefined
                        }
                    >
                        <div
                            className={utils.twcx(
                                "relative z-0 col-span-full flex min-h-[248px] flex-col overflow-hidden rounded-[20px] bg-[#ECECFF] px-4 py-5 text-steel-01 sm:col-span-4 md:col-span-6 md:min-h-[389px] md:rounded-[30px] md:p-6",
                                {
                                    "max-sm:mt-1": !cardIndex,
                                }
                            )}
                        >
                            <Text
                                className="text-[24px]/[110%] md:text-[32px]/[110%]"
                                weight={500}
                            >
                                {tHome(
                                    `sections.statistics.cards.${cardId}.title`
                                )}
                            </Text>

                            <Text
                                className="mt-1.5 md:mt-2"
                                variant="p2"
                                color="steel02"
                            >
                                {tHome(
                                    `sections.statistics.cards.${cardId}.date`
                                )}
                            </Text>

                            <div className="mt-3 flex items-end gap-x-2 md:mt-4 md:gap-x-2.5">
                                <Text className="text-[32px]/[110%] md:text-[40px]/[110%]">
                                    {tHome(
                                        `sections.statistics.cards.${cardId}.value`
                                    )}
                                </Text>

                                <div className="flex items-center gap-x-1 pb-0.5 md:pb-1">
                                    <Image
                                        className="w-3 shrink-0"
                                        src={imageTriangle}
                                        alt="Triangle"
                                        size="12px"
                                    />

                                    <Text variant="p2" weight={500}>
                                        {tHome(
                                            `sections.statistics.cards.${cardId}.percent`
                                        )}
                                    </Text>
                                </div>
                            </div>

                            <div className="-mx-4 -mb-5 mt-auto pt-1 md:-mx-6 md:-mb-6 md:pt-2">
                                {((windowSize.isMd && cardId === "sales") ||
                                    cardId !== "sales") && (
                                    <Image
                                        className={utils.twcx(
                                            "pointer-events-none w-full select-none",
                                            {
                                                "max-md:hidden":
                                                    cardId === "sales",
                                            }
                                        )}
                                        src={
                                            {
                                                units: imageChart1,
                                                sales: imageChart2,
                                            }[cardId]
                                        }
                                        alt="Chart"
                                    />
                                )}

                                {!windowSize.isMd && cardId === "sales" && (
                                    <Image
                                        className="pointer-events-none w-full select-none md:hidden"
                                        src={imageChart2Tablet}
                                        alt="Chart"
                                    />
                                )}
                            </div>
                        </div>
                    </AnimationFadeIn>
                ))}
            </Container>

            <Image
                className="pointer-events-none absolute left-1/2 top-[-615px] -z-10 ml-[-1279px] w-[2045px] max-w-none rotate-[16.79deg] select-none sm:top-[-940px] md:top-[-508px] md:ml-[-1140px] md:rotate-[-9.14deg] xl:top-[-35.2vw] xl:ml-[-79%] xl:w-[142%]"
                src={imageLines}
                alt="Lines"
                size="2045px"
            />
        </section>
    );
};
