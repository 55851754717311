"use client";

import { useDispatch } from "react-redux";

import { PAGES } from "@Settings/pages";
import { AnimationFadeIn } from "@Components/Animation";
import { Button } from "@Components/Button";
import { Container } from "@Components/Container";
import { Image } from "@Components/Image";
import { Text } from "@Components/Typography";
import { useTranslation } from "@Core/i18n/client";
import { openContactUsModal } from "@Modals/ContactUsModal";
import * as utils from "@Utils";

import imagePreview from "./images/preview.webp";

type SectionProps = {
    className?: string;
};

export const SectionAbout: React.FC<SectionProps> = ({ className }) => {
    const dispatch = useDispatch();
    const { t: tHome } = useTranslation("home");

    const handleContactUsClick = () => {
        dispatch(openContactUsModal());
    };

    return (
        <section
            className={utils.twcx(
                "relative my-[-200px] overflow-hidden pb-[250px] pt-[240px] md:pb-[280px] md:pt-[300px]",
                className
            )}
            id={utils.getIdFromLink(PAGES.home.sections.about.link)}
        >
            <Container className="items-center" grid>
                <div className="relative z-10 col-span-full sm:col-span-4 md:col-span-6 md:pb-12">
                    <AnimationFadeIn>
                        <Text
                            className="-mb-3 bg-gradient-01 bg-clip-text pb-3 text-transparent"
                            tag="h2"
                            variant="h2"
                            weight={600}
                        >
                            {tHome("sections.about.title")}
                        </Text>
                    </AnimationFadeIn>

                    <AnimationFadeIn>
                        <Text
                            className="mt-3 md:mt-4"
                            color="steelLight"
                            wysiwyg
                            html={tHome("sections.about.text")}
                        />
                    </AnimationFadeIn>

                    <AnimationFadeIn className="mt-8" parent>
                        <Button
                            variant="secondary"
                            onClick={handleContactUsClick}
                        >
                            {tHome("sections.about.buttonContactUs")}
                        </Button>
                    </AnimationFadeIn>
                </div>

                <AnimationFadeIn
                    className="col-span-full max-md:justify-self-center sm:col-span-4 md:col-span-6"
                    parent
                >
                    <Image
                        className="pointer-events-none mb-[-138px] ml-[-2px] mt-[-98px] w-[602px] max-w-none select-none sm:ml-0 md:my-[-186px] md:ml-[-92px] md:w-[812px]"
                        src={imagePreview}
                        alt="Interface"
                        size="602px"
                        sizeMd="812px"
                    />
                </AnimationFadeIn>
            </Container>
        </section>
    );
};
