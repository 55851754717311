"use client";

import { getImageProps } from "next/image";

import { AnimationFadeIn } from "@Components/Animation";
import { Container } from "@Components/Container";
import { Image } from "@Components/Image";
import { Text } from "@Components/Typography";
import { useTranslation } from "@Core/i18n/client";
import useWindowSize from "@Hooks/windowSize";
import * as utils from "@Utils";

import imageHandTablet from "./images/hand-t.webp";
import imageHand from "./images/hand.webp";
import imageLinesTablet from "./images/lines-t.svg";
import imageLines from "./images/lines.svg";

type SectionProps = {
    className?: string;
};

export const SectionHero: React.FC<SectionProps> = ({ className }) => {
    const { t: tHome } = useTranslation("home");

    const windowSize = useWindowSize();

    const imageHandCommonProps = {
        alt: "Hand",
        sizes: "(min-width: 1025px) 915px, 727px",
        priority: true,
        quality: 90,
    };

    const {
        props: { srcSet: imageHandSrcDesktop },
    } = getImageProps({
        ...imageHandCommonProps,
        src: imageHand,
    });

    const {
        props: { srcSet: imageHandSrcTablet, ...rest },
    } = getImageProps({
        ...imageHandCommonProps,
        src: imageHandTablet,
    });

    return (
        <section
            className={utils.twcx(
                "relative z-0 h-[850px] overflow-hidden bg-gradient-to-b from-[#282E46] via-[#44447A] via-[56.42%] to-[#0F1011] pt-[138px] before:pointer-events-none before:absolute before:bottom-0 before:left-0 before:right-0 before:z-30 before:block before:h-[301px] before:bg-gradient-to-b before:from-[#0D0E0E]/0 before:to-[#0E1121] before:to-[68.36%] md:h-[1010px] md:before:to-[80.49%]",
                className
            )}
        >
            <Container className="max-w-[360px] p-0 md:max-w-none">
                <AnimationFadeIn type="fadeIn">
                    <Text
                        className="-mb-3 bg-gradient-03 bg-clip-text pb-3 text-transparent"
                        tag="h1"
                        variant="h1"
                        weight={600}
                        align="center"
                    >
                        {tHome("sections.hero.title")}
                    </Text>
                </AnimationFadeIn>
            </Container>

            <AnimationFadeIn delay={150}>
                <picture className="pointer-events-none absolute bottom-[56px] left-1/2 z-20 ml-[-289px] w-[727px] max-w-none select-none md:bottom-0 md:ml-[-364px] md:w-[915px]">
                    <source
                        media="(max-width: 1024px)"
                        srcSet={imageHandSrcTablet}
                        sizes="727px"
                    />

                    <source
                        media="(min-width: 1025px)"
                        srcSet={imageHandSrcDesktop}
                        sizes="915px"
                    />

                    <img {...rest} />
                </picture>
            </AnimationFadeIn>

            {windowSize.isMd && (
                <Image
                    className="pointer-events-none select-none max-md:hidden md:absolute md:bottom-0 md:left-1/2 md:z-10 md:ml-[-1328px] md:w-[2450px] md:max-w-none 2xl:left-[-17%] 2xl:ml-0 2xl:w-[120%]"
                    src={imageLines}
                    alt="Lines"
                />
            )}

            {!windowSize.isMd && (
                <Image
                    className="pointer-events-none select-none max-md:absolute max-md:bottom-0 max-md:left-1/2 max-md:z-10 max-md:ml-[-600px] max-md:w-[1200px] max-md:max-w-none max-sm:ml-[-646px] max-sm:w-[1060px] md:hidden"
                    src={imageLinesTablet}
                    alt="Lines"
                />
            )}
        </section>
    );
};
