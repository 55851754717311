"use client";

import { PAGES } from "@Settings/pages";
import { AnimationFadeIn } from "@Components/Animation";
import { Container } from "@Components/Container";
import { Image } from "@Components/Image";
import { Text } from "@Components/Typography";
import { useTranslation } from "@Core/i18n/client";
import useWindowSize from "@Hooks/windowSize";
import * as utils from "@Utils";

import imageAbilitiesTablet from "./images/abilities-t.webp";
import imageAbilities from "./images/abilities.webp";
import imageBestTablet from "./images/best-t.webp";
import imageBest from "./images/best.webp";
import imageCircles from "./images/circles.svg";
import imagePhones from "./images/phones.webp";
import imagePlatformsTablet from "./images/platforms-t.svg";
import imagePlatforms from "./images/platforms.svg";
import imageStatsTablet from "./images/stats-t.webp";
import imageStats from "./images/stats.webp";

type SectionProps = {
    className?: string;
};

export const SectionAdvantages: React.FC<SectionProps> = ({ className }) => {
    const { t: tHome } = useTranslation("home");

    const windowSize = useWindowSize();

    return (
        <section
            className={utils.twcx(
                "relative z-0 mb-[-200px] mt-[-400px] overflow-hidden py-[250px] text-white md:py-[280px]",
                className
            )}
            id={utils.getIdFromLink(PAGES.home.sections.advantages.link)}
        >
            <Container className="relative z-10 gap-y-6 md:gap-y-8" grid>
                <AnimationFadeIn>
                    <Text
                        className="col-span-full sm:col-span-6 sm:col-start-2 sm:text-center md:col-span-8 md:col-start-3"
                        tag="h2"
                        variant="h2"
                        weight={600}
                    >
                        {tHome("sections.advantages.title")}
                    </Text>
                </AnimationFadeIn>

                <AnimationFadeIn>
                    <div className="col-span-full flex flex-col gap-y-2 overflow-hidden rounded-[20px] bg-gradient-to-b from-[#91A8FA] to-[#434390] px-4 py-5 shadow-[0px_12px_40px_rgba(0,0,0,0.12)] md:col-span-6 md:gap-y-3 md:rounded-[30px] md:px-6 md:py-8">
                        <Text tag="h3" variant="h4" weight={600}>
                            {tHome("sections.advantages.mainCard.title")}
                        </Text>

                        <Text tag="p">
                            {tHome("sections.advantages.mainCard.text")}
                        </Text>

                        <Image
                            className="pointer-events-none relative top-[-15px] mb-[-138px] ml-[40px] mt-auto w-[386px] max-w-none select-none self-center md:top-[-14px] md:mb-[-206px] md:ml-[62px] md:w-[602px]"
                            src={imagePhones}
                            alt="Phones"
                            size="386px"
                            sizeMd="602px"
                        />
                    </div>
                </AnimationFadeIn>

                <div className="col-span-full grid auto-rows-fr gap-3 max-md:-mt-3 xs:grid-cols-6 md:col-span-6 md:gap-4">
                    {["best", "stats", "abilities", "platforms"].map(
                        (cardId, cardIndex) => (
                            <AnimationFadeIn
                                key={cardId}
                                delay={
                                    windowSize.isXs
                                        ? ((cardIndex % 2) + 1) * 100
                                        : undefined
                                }
                            >
                                <div
                                    className={utils.twcx(
                                        "col-span-full flex flex-col justify-end gap-y-2 overflow-hidden rounded-[20px] bg-gradient-02 px-4 py-5 shadow-[0px_12px_40px_rgba(0,0,0,0.12)] xs:col-span-3 md:rounded-[30px] md:py-6",
                                        {
                                            "max-xs:order-2":
                                                cardId === "stats",
                                            "max-xs:order-1":
                                                cardId === "abilities",
                                            "max-xs:order-3":
                                                cardId === "platforms",
                                        }
                                    )}
                                >
                                    <div
                                        className={utils.twcx("mb-auto", {
                                            "relative top-[-7px] mt-auto w-[274px] max-w-full md:top-[-19px] md:w-[223px]":
                                                cardId === "best",
                                            "ml-[-39px] mr-[-29px] mt-[-30px] md:ml-[-42px] md:mr-[-23px] md:mt-[-51px]":
                                                cardId === "stats",
                                            "ml-[-27px] mr-[-28px] mt-[5px] md:ml-[-22px] md:mr-[-23px] md:mt-[-22px]":
                                                cardId === "abilities",
                                            "relative mt-auto w-[272px] max-w-full self-center md:top-[-8px] md:w-[211px]":
                                                cardId === "platforms",
                                        })}
                                    >
                                        {windowSize.isMd && (
                                            <Image
                                                className={utils.twcx(
                                                    "pointer-events-none w-full select-none max-md:hidden",
                                                    {
                                                        "md:mb-[-13px]":
                                                            cardId === "stats",
                                                    }
                                                )}
                                                src={
                                                    {
                                                        best: imageBest,
                                                        stats: imageStats,
                                                        abilities:
                                                            imageAbilities,
                                                        platforms:
                                                            imagePlatforms,
                                                    }[cardId]
                                                }
                                                alt={cardId}
                                                size={
                                                    {
                                                        best: "223px",
                                                        stats: "316px",
                                                        abilities: "296px",
                                                        platforms: "211px",
                                                    }[cardId]
                                                }
                                            />
                                        )}

                                        {!windowSize.isMd && (
                                            <Image
                                                className={utils.twcx(
                                                    "pointer-events-none w-full select-none md:hidden",
                                                    {
                                                        "max-md:mb-[-35px]":
                                                            cardId === "stats",
                                                    }
                                                )}
                                                src={
                                                    {
                                                        best: imageBestTablet,
                                                        stats: imageStatsTablet,
                                                        abilities:
                                                            imageAbilitiesTablet,
                                                        platforms:
                                                            imagePlatformsTablet,
                                                    }[cardId]
                                                }
                                                alt={cardId}
                                                size={
                                                    {
                                                        best: "274px",
                                                        stats: "105vw",
                                                        abilities: "105vw",
                                                        platforms: "272px",
                                                    }[cardId]
                                                }
                                                sizeXs="55vw"
                                            />
                                        )}
                                    </div>

                                    <Text tag="h4" variant="h5" weight={600}>
                                        {tHome(
                                            `sections.advantages.cards.${cardId}.title`
                                        )}
                                    </Text>

                                    <Text
                                        tag="p"
                                        variant="p3"
                                        color="steelLight"
                                    >
                                        {tHome(
                                            `sections.advantages.cards.${cardId}.text`
                                        )}
                                    </Text>
                                </div>
                            </AnimationFadeIn>
                        )
                    )}
                </div>
            </Container>

            <Image
                className="pointer-events-none absolute left-1/2 top-[202px] -z-10 w-[1122px] max-w-none -translate-x-1/2 select-none md:top-[179px] md:w-[1440px]"
                src={imageCircles}
                alt="Circles"
                size="1122px"
                sizeMd="1440px"
            />
        </section>
    );
};
